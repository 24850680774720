import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FacebookAppId, GoogleClientId } from '@app/core/services/social-auth.service';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { AgreementTermsFieldModule } from 'lingo2-forms';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgOtpInputModule } from 'ng-otp-input';
import { CountdownModule } from 'ngx-countdown';
import { NgxIntlPhoneInputModule } from 'ngx-intl-phone-input';
import { NgxTurnstileModule } from 'ngx-turnstile';
import { OnUiButtonModule, OnUiSelectSearchModule } from 'onclass-ui';
import { CoreModule } from '../../core.module';
import { AuthInputFieldComponent } from './auth-input-field/auth-input-field.component';
import { AuthModalComponent } from './auth-modal.component';
import { GoogleSigninButtonDirective } from './google-auth-button.directive';
import { ViewAuthRestoreComponent } from './views/restore/view-auth-restore.component';
import { ViewAuthSignInComponent } from './views/signin/view-auth-signin.component';
import { ViewAuthSignUpComponent } from './views/signup/view-auth-signup.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    CoreModule,
    FormsModule,
    NgxTurnstileModule,
    NgOtpInputModule,
    CountdownModule,
    ModalModule,
    MobileModalModule,
    OnUiButtonModule,
    AgreementTermsFieldModule,
    NgxIntlPhoneInputModule,
    NgOptimizedImage,
    IconComponent,
    OnUiSelectSearchModule,
    ClickOutsideModule,
  ],
  exports: [AuthModalComponent, AuthInputFieldComponent],
  declarations: [
    AuthModalComponent,
    AuthInputFieldComponent,
    GoogleSigninButtonDirective,
    ViewAuthSignInComponent,
    ViewAuthSignUpComponent,
    ViewAuthRestoreComponent,
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(GoogleClientId),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(FacebookAppId),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
})
export class AuthModalModule {}
