import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IControlOption } from '@app/shared';
import { CountryISO } from 'ngx-intl-phone-input';

const validationMessages: any = {
  required: 'auth.warnings.empty',
  email: 'auth.warnings.wrong-email',
  validatePhoneNumber: 'auth.warnings.wrong-email',
  invalidEmail: 'auth.warnings.invalid-email',
  invalidPass: 'auth.warnings.invalid-pass',
  mustMatch: 'auth.warnings.pass-not-equal',
  wrongOldPassword: 'auth.warnings.wrong-old-password',
  wrongNewPassword: 'auth.warnings.wrong-new-password',
  pattern: 'auth.warnings.wrong-email',
  minlength: 'auth.errors.signup.password_invalid',
};

@Component({
  selector: 'app-auth-input-field',
  templateUrl: './auth-input-field.component.html',
  styleUrls: ['./auth-input-field.component.scss'],
})
export class AuthInputFieldComponent {
  @Input() form: UntypedFormGroup;
  @Input() controlName: string;

  @Input() set options(options: IControlOption[]) {
    this._options = options;
  }

  public get options() {
    return this._options;
  }

  @Input() set type(type: string) {
    this._type = type;
  }

  get type(): string {
    return this._type;
  }

  @Input() placeholder: string;
  @Input() dataTestName: string;
  @Input() showValidation: boolean;
  @Input() displayLeftIco = true;
  @Input() localization: 'en' | 'ru' = 'en';

  @Input() set countryCode(value: CountryISO) {
    if (value) {
      this._countryCode = value;
    }
  }

  public get countryCode() {
    return this._countryCode;
  }

  // tslint:disable-next-line: no-output-on-prefix
  @Output() onEnter = new EventEmitter<boolean>();
  @Output() onChanged = new EventEmitter<any>();

  public focusField = false;
  public showPassword = false;
  public _type;
  public _options;
  public _countryCode: CountryISO;

  public placeholderValue(): string {
    return this.focusField ? '' : this.placeholder;
  }

  public isCountry(): boolean {
    return this.type === 'country';
  }

  public isPhone(): boolean {
    return this.type === 'phone';
  }

  public isPassword(): boolean {
    return this.type === 'password';
  }

  public isAgreement(): boolean {
    return this.type === 'agreement';
  }

  public get warnField(): boolean {
    return this.form.controls[this.controlName].invalid;
  }

  public toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  public get message() {
    const [error] = Object.keys(this.form.controls[this.controlName].errors);
    return validationMessages[error] || '';
  }

  public onChange(event) {
    this.onChanged.emit(event);
  }
}
